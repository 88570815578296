<template>
  <div id="footer">
    <div v-show="showList" class="footer-list">
      <el-row type="flex" justify="center">
        <el-col :md="20" :lg="18" :xl="15">
          <div style="width: 100%; max-width: 1284px; margin: 0 auto;">
            <div class="hope">
              <div class="logo">
                <img src="../assets/logo/hope_36x50@2x.png" alt="Hope" width="32px">
              </div>
              <div class="name">
                <div class="name-tenyukai">医療法人社団 天裕会</div>
                <div class="name-nozomi">のぞみクリニック{{ this.clinicName }}</div>
              </div>
            </div>
            <div class="contents">
              <div class="left">
                <div class="address">
                  <div>〒104-0045</div>
                  <div>東京都中央区築地7-11-5 中銀ベル築地マンシオン2階</div>
                  <div>東京メトロ日比谷線 築地駅1番、2番出口より徒歩4分</div>
                </div>
                <div class="contact">
                  <div class="contact-left">
                    <div>診療科目：一般内科、ダイエット</div>
                    <div>診療時間：10:00 ~ 13:30 / 14:30 ~ 19:00</div>
                  </div>
                  <div class="contact-right">
                    <div>TEL/FAX：03-6260-6008</div>
                    <div>Mail：info@hope-medical.jp</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="right">
              <div class="title">サイトマップ</div>
              <div class="link-lists">
                <div>
                  <i class="el-icon-caret-right"></i>
                  <el-link :underline="false" @click="goTo('/information')">お知らせ</el-link>
                </div>
                <div>
                  <i class="el-icon-caret-right"></i>
                  <el-link :underline="false" @click="goTo('/department/internal')">胃腸科</el-link>
                </div>
                <div>
                  <i class="el-icon-caret-right"></i>
                  <el-link :underline="false" @click="goTo('/about')">当院について</el-link>
                </div>
                <div>
                  <i class="el-icon-caret-right"></i>
                  <el-link :underline="false" @click="goTo('/department/kampo')">漢方内科</el-link>
                </div>
                <div>
                  <i class="el-icon-caret-right"></i>
                  <el-link :underline="false" @click="goTo('/access')">アクセス</el-link>
                </div>
                <div>
                  <i class="el-icon-caret-right"></i>
                  <el-link :underline="false" @click="goTo('/department/placenta')">腫瘍外科</el-link>
                </div>
                <div>
                  <i class="el-icon-caret-right"></i>
                  <el-link :underline="false" @click="goTo('/sending')">お間い合わせ</el-link>
                </div>
                <div>
                  <i class="el-icon-caret-right"></i>
                  <el-link :underline="false" @click="goTo('/second')">セカンドオピニオン</el-link>
                </div>
                <div>
                  <i class="el-icon-caret-right"></i>
                  <el-link :underline="false" @click="goTo('/department/sas')">睡眠時無呼吸症候群</el-link>
                </div>
                <div>
                  <i class="el-icon-caret-right"></i>
                  <el-link :underline="false" @click="goTo('/department/dermatology')">皮膚科</el-link>
                </div>
                <div>
                  <i class="el-icon-caret-right"></i>
                  <el-link :underline="false" @click="goTo('/department/asp')">高山病予防</el-link>
                </div>
                <div>
                  <i class="el-icon-caret-right"></i>
                  <el-link :underline="false" @click="goTo('/department/pediatrics')">小児科</el-link>
                </div>
                <div>
                  <i class="el-icon-caret-right"></i>
                  <el-link :underline="false" @click="goTo('/topic')">トピックス</el-link>
                </div>
              </div>
            </div> -->
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="copyright">
      <span>Copyright©{{thisYear}} Hope Medical. All rights reserved.</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageFooter",
  data() {
    return {
      thisYear: (new Date()).getFullYear()
    }
  },
  props: {
    showList: {
      default: true
    },
    clinicName: {
      default: '築地',
    },
    pathName: {
      default: 'tsukiji'
    }
  },
  methods: {
    goTo(name) {
      this.$router.push(name)
    }
  }
}
</script>

<style scoped>
#footer {
  background-color: #FAFDFF;
  height: 272px;
}
.footer-list {
  color: #606266;
  padding: 30px 0;
}
.contents {
  align-items: center;
  display: flex;
}
/* hope */
.hope {
  align-items: center;
  display: flex;
}
.hope .name {
  margin-left: 14px;
}
.name-tenyukai {
  color: #9E9E9E;
  font-size: 12px;
  font-weight: bold;
}
.name-nozomi {
  color: #62B6E2;
  font-size: 20px;
  font-weight: bold;
}
/* left */
.left .address {
  font-size: 12px;
  margin-top: 20px;
}
.left .contact {
  display: flex;
  font-size: 12px;
  margin-top: 14px;
}
.left .contact-right {
  margin-left: 30px;
}

/* right */
.right {
  margin-left: 30px;
}
.right .title {
  color: #9E9E9E;
  font-size: 12px;
  font-weight: bold;
}
.right .link-lists {
  display: grid;
  grid-template-columns: 33% 33% 34%;
  margin-top: 10px;
}
.right .link-lists div {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0;
}
.right .link-lists .link-list {
  color: #9E9E9E;
  font-size: 12px;
}
.right .link-lists .link-list:hover {
  color: #62b6e2;
}

/* copyright */
#footer .copyright {
  height: 50px;
  background-color: #62b6e2;
  /*水平和竖直居中*/
  display: flex;
  justify-content: center;
  align-items: center;

  /*字体格式*/
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 3px;
}
#footer .copyright span {
  text-align: center;
}
</style>
